import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Key } from '../../constants/key';

@Component({
  selector: '[son-keys-table-row]',
  templateUrl: './keys-table-row.component.html',
  styleUrls: ['./keys-table-row.component.scss'],
})
export class KeysTableRowComponent {
  @Input() key: Key;
  @Input() checked = false;
  @Output() ontoggle = new EventEmitter();
  @Output() onedit = new EventEmitter();

  checkBoxChange = ({ target: { checked } }) =>
    this.ontoggle.emit({ checked, key: this.key });
}
