export function isArrayOfObjects(array) {
  let result = false;
  for (const key in array) {
    result = typeof array[key] === 'object';
    break;
  }
  return result;
}

export function toErrorFormat(obj, errors = []) {
  for (const key in obj) {
    const hasKey = obj.hasOwnProperty(key); // object is not empty
    const isObject = typeof obj[key] === 'object'; // object is object
    const isArray = Array.isArray(obj[key]); // object is Array

    if (hasKey && isObject && !isArray) {
      toErrorFormat(obj[key], errors);
    } else if (hasKey && isObject && isArray) {
      // push error object to result array
      errors = errors.concat(obj[key]);
      toErrorFormat(obj[key], errors);
    } else {
      // push error object to result array
      errors.push(obj[key]);
    }
  }
  return errors;
}
