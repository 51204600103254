import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class TitlesService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get('greeting-titles/').toPromise();
  }

  update(id, title) {
    return this.http.put(`greeting-titles/${id}/`, title).toPromise();
  }

  create(type, value) {
    return this.http.post('greeting-titles/', { type, value }).toPromise();
  }

  delete(id) {
    return this.http.delete(`greeting-titles/${id}/`).toPromise();
  }
}
