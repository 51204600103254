export const FIELDS = [
  {
    type: 'multi-box',
    objectKey: 'field_text',
    name: 'correlation',
    title: 'Correlation',
    hasErrors: false,
    isActive: true,
    showEditPencil: false,

  },
  {type: 'multi-box', name: 'category', title: 'Category', hasErrors: false, isActive: true , objectKey: 'field_text', showEditPencil: true},
  {
    type: 'multi-box',
    name: 'subcategory',
    title: 'Subcategory',
    objectKey: 'field_text',
    hasErrors: false,
    isActive: true,
    showEditPencil: true,
  },
  {type: 'string', name: 'muscle_id', title: 'Muscle ID', hasErrors: false, isActive: true},
  {
    type: 'multi-box',
    name: 'flag_category',
    objectKey: 'field_text',
    title: 'Vitality Indicator',
    hasErrors: false,
    showEditPencil: true,
    isActive: true,
  },
  {
    type: 'multi-box',
    name: 'flag_direction',
    objectKey: 'field_text',
    title: 'Direction ',
    hasErrors: false,
    isActive: true,
    showEditPencil: true,

    
  },
  {
    type: 'multi-box',
    name: 'fundamental',
    objectKey: 'field_text',
    title: 'Fundamental Frequency',
    hasErrors: false,
    isActive: true,
    showEditPencil: false,

  },
  
  {type: 'string', name: 'min', title: 'Min value', hasErrors: false, isActive: true},
  {type: 'string', name: 'max', title: 'Max value', hasErrors: false, isActive: true},
  {
    type: 'multi-box',
    name: 'recommendation',
    objectKey: 'field_text',
    title: 'Default recommendation',
    hasErrors: false,
    isActive: true,
    showEditPencil: false,

  },
  {
    type: 'multi-box',
    name: 'recommendation_if_high',
    objectKey: 'field_text',
    title: 'Recommendation for high score',
    hasErrors: false,
    isActive: true,
    showEditPencil: false,

  },
  {
    type: 'multi-box',
    name: 'recommendation_if_low',
    objectKey: 'field_text',
    title: 'Recommendation for low score',
    hasErrors: false,
    isActive: true,
    showEditPencil: false,

  },
  {
    type: 'string',
    name: 'function',
    title: 'Function',
    hasErrors: false,
    isActive: true,
  },
  {
    type: 'string',
    name: 'scientific_name',
    title: 'Scientific name',
    hasErrors: false,
    isActive: true,
  },
  {
    type: 'string',
    name: 'areas_affected_muscle_origin_insertion',
    title: 'Areas affected muscle origin insertion',
    hasErrors: false,
    isActive: true,
  },
  {
    type: 'string',
    name: 'conditions_complaints',
    title: 'Conditions complaints',
    hasErrors: false,
    isActive: true,
  },
  {
    type: 'string',
    name: 'recommendations_sources',
    title: 'Recommendations sources',
    hasErrors: false,
    isActive: true,
  },
  {
    type: 'boolean',
    name: 'can_be_used_in_api',
    title: 'Is used in API',
    hasErrors: false,
    isActive: true,
  },
];
