import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlagsDbService {
  constructor(private http: HttpClient) {}

  get(params?) {
    return this.http.get('constants/flags/', { params }).toPromise();
  }

  post(body) {
    return this.http
      .post('constants/flags-revisions/', { ...body })
      .toPromise();
  }

  createRevision(body, overrides) {
    return this.post({
      updates: [{ new_rows_data: { ...body } }],
      overrides,
    });
  }

  updateRevision(id, body, overrides) {
    return this.post({
      updates: [{ new_rows_data: { ...body, id }, previous_rows_data: { id } }],
      overrides
    });
  }

  removeRevision(id) {
    return this.post({
      updates: [{ previous_rows_data: { id } }]
    });
  }

  getRevisions(params?) {
    return this.http.get('constants/flags-revisions/', { params }).toPromise();
  }

  getHistory(params?) {
    return this.getRevisions({ ...params, revision_state: 'History', });
  }

  getPending(params?) {
    return this.getRevisions({ ...params, revision_state: 'Pending' });
  }

  acceptPending(id) {
    return this.http
      .put(`constants/flags-revisions/${id}/accept/`, {})
      .toPromise();
  }

  declinePending(id) {
    return this.http
      .put(`constants/flags-revisions/${id}/decline/`, {})
      .toPromise();
  }
  getAppList(): Promise<any> {
    return this.http.get(`constants/apps`).toPromise();
  }
}
