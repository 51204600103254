import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CoreModule } from './modules/core.module';
import { FeatureModule } from './modules/feature.module';
import { PopUpService } from './services/pop-up.service';
import { NgxMaskModule } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserIdleModule } from 'angular-user-idle';


@NgModule({
  declarations: [AppComponent],
  providers: [PopUpService],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    FeatureModule,
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    UserIdleModule.forRoot({ idle: 600, timeout: 1, ping: 1 }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
