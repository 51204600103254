
export function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/^(.)/, function ($1) {
      return $1.toUpperCase();
    });
}
