import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUser } from 'src/app/constants/adminUser';

@Component({
  selector: '[son-admin-table-row]',
  templateUrl: './admin-table-row.component.html',
  styleUrls: ['./admin-table-row.component.scss'],
})
export class AdminTableRowComponent {
  @Input() user: AdminUser;
  @Input() checked = false;
  @Input() editable = false;
  @Output() ontoggle = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit() {}
  edit() {
    this.router.navigate([`/dashboard/edit-admin/${this.user.id}`], {
      state: { user: this.user },
    });
  }
  checkBoxChange = ({ target: { checked } }) =>
    this.ontoggle.emit({ checked, user: this.user });
}
