import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange
} from '@angular/core';
interface CovdQuestion {
  question_text: string;
  reply: boolean;
}
@Component({
  selector: 'son-covd-questions',
  templateUrl: './covd-questions.component.html',
  styleUrls: ['./covd-questions.component.scss']
})
export class CovdQuestionsComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() questions: CovdQuestion[] = [];

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    
  }
}
