import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'son-sort-arrows',
  templateUrl: './sort-arrows.component.html',
  styleUrls: ['./sort-arrows.component.scss'],
})
export class SortArrowsComponent implements OnInit {
  @Input() ordering: string;
  @Input() title: string;
  @Input() asc: string;
  @Input() desc: string;
  @Output() orderingChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  setOrdering(value) {
    this.ordering = value;
    this.orderingChange.emit(value);
  }
}
