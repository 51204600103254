import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Attribute } from '../constants/Attribute.type';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get('attributes/').toPromise();
  }

  create(form: Attribute) {
    return this.http.post('attributes/', form).toPromise();
  }

  update(form: Attribute) {
    return this.http.put(`attributes/${form.id}/`, form).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`attributes/${id}/`).toPromise();
  }
}
