import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFlagRow'
})
export class FormatFlagRowPipe implements PipeTransform {
  transform(field: any, row): any {
    // IF it is BOOL return YES or NO

    if (field.isActive && field.type === 'boolean') {
      return row[field.name] ? 'Yes' : 'No';
    }
    // IF it is no BOOL
    if (field.isActive && field.type !== 'boolean') {
      // IF it is complicated field has objectKey
      if (field.objectKey && row[field.name]) {
        // IF it is Array join objectKey
        if (Array.isArray(row[field.name])) {
          return row[field.name].map(e => e[field.objectKey]).join(',');
        } else {
          // else it is object so we return only objectKey value
          return row[field.name][field.objectKey];
        }
      } else return row[field.name];
    }
  }
}
