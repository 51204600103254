import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/**
 * pass format param to pipe for customization
 */
@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string | number, format = 'MM/DD/YYYY hh:mm A'): any {
    if (date) return moment(date).format(format);
    return date;
  }
}
