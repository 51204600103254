import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data: any) {
    return this.http.post('auth/login/', data).toPromise();
  }
  verifyTwoAuth(code: string, email: string) {
    return this.http
      .post<{ token: string }>('auth/login/verify/', { code, email })
      .toPromise();
  }
  adminRegistration(data: any) {
    return this.http.post('auth/register/', data).toPromise();
  }

  changePasswordOfCurrentUser(data: any) {
    return this.http.post('auth/password/change/', data).toPromise();
  }

  changePassword(userId: number, data: any) {
    return this.http.put(`auth/password/change/${userId}/`, data).toPromise();
  }
  getMe() {
    return this.http.get(`auth/user/`).toPromise();
  }

  sendPasswordResetEmail(email) {
    return this.http.post('auth/password/reset/', { email }).toPromise();
  }

  resetPassword(data) {
    return this.http.post('auth/password/reset/confirm/', data).toPromise();
  }
}
