import {Component, OnInit, Input, OnChanges, NgZone} from '@angular/core';

@Component({
  selector: 'son-status-ring',
  templateUrl: './status-ring.component.html',
  styleUrls: ['./status-ring.component.scss'],
})
export class StatusRingComponent implements OnInit, OnChanges {
  @Input() percents = 75;
  @Input() id: number;
  @Input() bgColor = '#eee';
  @Input() circleBorderColor = '#fff';

  constructor(public ngZone: NgZone) {
  }

  ngOnChanges(changes) {
    if (changes.percents && changes.percents.currentValue) {
      this.ngZone.runOutsideAngular(() => {

        setTimeout(() => {
          new CanvasCircleLoader({
            percents: this.percents,
            canvas: document.querySelector(`#ring${this.id} > canvas`),
          });
        }, 0);
      });
     
    }
  }

  ngOnInit() {
  }
}

class CanvasCircleLoader {
  canvas: any;
  lineWidth: any;
  circleColors: any;
  colors: any;
  percents: any;
  bgColor: any;
  circleBorderColor: any;
  procent: any;
  deegres: any;
  ctx: any;
  result: any;
  positionX: any;
  positionY: any;
  R: any;
  rad: any;
  oneProcent: any;
  padding: any;
  A: any;

  constructor({
                canvas,
                percents = 90,
                bgColor = '#eee',
                circleBorderColor = '#fff',
                id = 0,
              }) {
    const lineWidth = 25;
    const defaultSize = 250;

    this.canvas = canvas;

    this.canvas.width = this.canvas.width ? this.canvas.width : defaultSize;
    this.canvas.height = this.canvas.height ? this.canvas.height : defaultSize;

    this.lineWidth = (lineWidth * this.canvas.width) / defaultSize;

    this.circleColors = [
      '#DB7155',
      '#E5660F',
      '#EDB110',
      '#87BB40',
      '#33C76C',
      '#33C66D',
    ];

    this.colors = [
      {from: '#E5660F', to: '#EDB110'},
      {from: '#EDB110', to: '#87BB40'},
      {from: '#87BB40', to: '#33C76C'},
      {from: '#33C76C', to: '#33C66D'},
      {from: '#33C66D', to: '#DB7155'},
      {from: '#DB7155', to: '#E5660F'},
    ];

    this.percents = percents;
    this.bgColor = bgColor;
    this.circleBorderColor = circleBorderColor;

    this.procent = 0;
    this.deegres = 0;

    // don't touch
    this.padding = this.lineWidth;

    this.ctx = canvas.getContext('2d', {alpha: true});
    this.oneProcent = 360 / 100;
    this.result = this.oneProcent * this.percents;
    this.positionX = this.canvas.width;
    this.positionY = this.canvas.height;
    this.R = this.canvas.width / 2 - this.padding;
    this.A = 360 / this.colors.length;
    this.rad = Math.PI / 180;
    this.draw();
  }

  draw() {
    this.deegres = 0;
    this.grayLayer();
  }

  gradientLayer() {
    this.ctx.lineWidth = this.lineWidth;
    let grd, apRad, afRad, xp, yp, xf, yf, ap, af;
    this.colors.forEach((item, i) => {
      ap = this.A * i;
      af = this.A * (i + 1) + 0.5;

      apRad = this.rad * ap;
      afRad = this.rad * af;

      this.ctx.beginPath();
      this.ctx.arc(
        this.positionX / 2,
        this.positionY / 2,
        this.R,
        apRad,
        afRad,
      );

      xp = this.positionX / 2 + this.R * Math.cos(apRad);
      yp = this.positionY / 2 + this.R * Math.sin(apRad);
      xf = this.positionX / 2 + this.R * Math.cos(afRad);
      yf = this.positionY / 2 + this.R * Math.sin(afRad);

      grd = this.ctx.createLinearGradient(xp, yp, xf, yf);

      grd.addColorStop(0, item.from);
      grd.addColorStop(1, item.to);
      this.ctx.strokeStyle = grd;
      this.ctx.stroke();
    });
  }

  getx(rad, angle) {
    return rad * Number(Math.cos(angle * this.rad).toFixed(3));
  }

  gety(rad, angle) {
    return rad * Number(Math.sin(angle * this.rad).toFixed(3));
  }

  circleLayer() {
    const angle = (360 * this.procent) / 100 - 90;
    const pointX = this.positionX / 2 + this.getx(this.R, angle);
    const pointY = this.positionY / 2 + this.gety(this.R, angle);

    const num = Math.floor(this.procent / (100 / (this.circleColors.length - 1)));

    this.ctx.beginPath();
    this.ctx.arc(
      pointX,
      pointY,
      this.lineWidth,
      this.rad * 270,
      this.rad * (270 + 360),
    );
    this.ctx.fillStyle = this.circleColors[num];
    this.ctx.strokeStyle = this.circleBorderColor;
    this.ctx.lineWidth = '2';

    this.ctx.fill();
    this.ctx.stroke();
  }

  grayLayer() {

    this.deegres += 5;

    this.procent = this.deegres / this.oneProcent;
    this.ctx.clearRect(0, 0, this.positionX, this.positionY);
    this.gradientLayer();

    this.ctx.beginPath();
    this.ctx.arc(
      this.positionX / 2,
      this.positionY / 2,
      this.R,
      this.rad * (270 + this.deegres),
      this.rad * 270,
    );
    this.ctx.strokeStyle = this.bgColor;
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.stroke();
    this.circleLayer();
      
    if (this.deegres <= this.result) {
      requestAnimationFrame(this.grayLayer.bind(this));
    }
  }
}
