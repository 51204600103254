import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../constants/user';
import { Role } from '../../helper/Role';

@Component({
  selector: '[son-user-table-row]',
  templateUrl: './user-table-row.component.html',
  styleUrls: ['./user-table-row.component.scss'],
})
export class UserTableRowComponent implements OnInit {
  @Input() user: User;
  @Input() dbs: { condition: any; configuration: any };
  @Input() checked = false;
  @Input() canEditCondition = false;
  @Input() canEditConfig = false;
  @Input() editable = true;
  @Output() ontoggle = new EventEmitter();

  selectedConditionDB;
  selectedConfigurationDb;
  role = Role;

  constructor(
    private router: Router,
  ) {}


  ngOnInit() {
    this.user._groups = this.user.groups.map(e => e.name).join(',');
  }

  editUser() {
    this.router.navigate([`/dashboard/edit-user/${this.user.id}`], {
      state: { user: this.user },
    });
  }

  checkBoxChange = ({ target: { checked } }) =>
    this.ontoggle.emit({ checked, user: this.user });
}
