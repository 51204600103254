import { Component, Input, HostListener, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: '[son-request-table-row]',
  templateUrl: './request-table-row.component.html',
  styleUrls: ['./request-table-row.component.scss'],
})
export class RequestTableRowComponent implements OnDestroy {
  constructor(public userService: UserService) {}

  @Input() request: any;

  shouldDeleteOnLeave = false;
  @HostListener('window:beforeunload')
  onLeave() {
    if (this.shouldDeleteOnLeave) {
      this.userService.changeReqStatus(this.request.id, {
        is_confirmed: false,
      });
    }
  }

  getStatusName() {
    if (this.request.is_confirmed) return 'Approved';
    if (this.request.is_confirmed == false) return 'Declined';
    if (this.request.is_confirmed == null) return '';
  }
  async changeStatus(is_confirmed) {
    if (is_confirmed === false) {
      this.request.is_confirmed = false;
      this.shouldDeleteOnLeave = true;
    } else {
      this.shouldDeleteOnLeave = false;
      this.request = await this.userService.changeReqStatus(this.request.id, {
        is_confirmed,
      });
    }
  }

  ngOnDestroy() {
    if (this.shouldDeleteOnLeave) {
      this.userService.changeReqStatus(this.request.id, {
        is_confirmed: false,
      });
    }
  }
}
