import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'son-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  public isAuthPage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private us: UserService
  ) {}

  ngOnInit() {
    console.log(this.route.pathFromRoot);
  }

  get isLogged() {
    const isAuth = this.router.url.includes('auth');
    return StorageService.token() && !isAuth;
  }

  Logout() {
    this.us.query = this.us.defaultQuery;
    this.us.logout();

  }

  dropdownToggle(event) {
    const configsDropdown = document.querySelector(
      '[data-selector="configs-dropdown"]'
    );
    const dropdownMenu = document.querySelector(
      '[data-selector="dropdown-menu"]'
    );

    const isShowed = configsDropdown.classList.contains('show');

    if (isShowed) {
      configsDropdown.classList.remove('show');
      dropdownMenu.classList.remove('show');
    } else {
      configsDropdown.classList.add('show');
      dropdownMenu.classList.add('show');
    }
  }
}
