import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private http: HttpClient) {}

  get(query) {
    return this.http.get('tags/', { params: query }).toPromise();
  }
  create(tag) {
    return this.http.post(`tags/`, tag).toPromise();
  }
}
