import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'son-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  @Output() dismiss = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
