import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { RuleService } from '../../services/rules.service';
import Rule from 'src/app/constants/rules.types';

@Component({
  selector: 'son-over-rules',
  templateUrl: './over-rules.component.html',
  styleUrls: ['./over-rules.component.scss']

})
export class OverRulesComponent implements OnInit {
  @Input() apps;
  @Input() rowId;
  @Input() meta;
  @Output() onSmthChanged = new EventEmitter();
  rules: any = [];
  rulesBeforeSave: any = [];

  keys = [
    {
      key: 'fundamental',
      placeholder: 'Fundamental Frequency'
    },
    {
      key: 'recommendation',
      placeholder: 'Recommendation'
    },
    {
      key: 'recommendation_if_high',
      placeholder: 'Recommendation for high score'
    },
    {
      key: 'recommendation_if_low',
      placeholder: 'Recommendation for low score'
    }
  ];
  constructor(private rs: RuleService) {}

  async ngOnInit() {
    let rules: Rule[] = [];
    if (this.rowId) rules = await this.rs.getByRowID(this.rowId);
    const formatedRules = rules.map(e => {
      if (!e.fundamental) e.fundamental = { field_text: '' };
      if (!e.recommendation) e.recommendation = { field_text: '' };
      if (!e.recommendation_if_high)
        e.recommendation_if_high = { field_text: '' };
      if (!e.recommendation_if_low)
        e.recommendation_if_low = { field_text: '' };
      return e;
    });
    console.log(formatedRules);
    this.rs.rules = formatedRules;
    this.rs.rulesBeforeSave = [...formatedRules];
  }

  onAddRules() {
    if (this.rs.rules.length >= this.apps.length) return;
    this.rs.rules.push({
      app: {},
      flags_db_row: { id: this.rowId },
      fundamental: { field_text: '' },
      recommendation: { field_text: '' },
      recommendation_if_high: { field_text: '' },
      recommendation_if_low: { field_text: '' }
    });
    this.onSmthChanged.emit(true);
  }
  onDeleteRules(index) {
    this.rs.rules.splice(index, 1);
    this.onSmthChanged.emit(true);
  }
  checkForDisablingApp(id: number) {
    const disabled = this.rs.rules.some(rule => {
      return rule.app.id == id;
    });
    return disabled;
  }
  trackByMethod(index: number, el: any): number {
    return index;
  }
  trackByKey(index: number, el: any): number {
    return el.key;
  }
}
