export class StorageService {
  static set(field: string, value: any, sessionOnly = false) {
    sessionOnly ?
      sessionStorage.setItem(field, JSON.stringify(value))
      : localStorage.setItem(field, JSON.stringify(value));
  }

  static get(field: string, sessionOnly = false) {
    try {
      return sessionOnly ?
        JSON.parse(sessionStorage.getItem(field))
        : JSON.parse(localStorage.getItem(field));
    } catch (error) {
      return null;
    }
  }

  static remove(field: string) {
    localStorage.removeItem(field);
    sessionStorage.removeItem(field);
  }

  static token() {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
  }

  static setToken(token, onlySession = true) {
    onlySession
      ? sessionStorage.setItem('token', token)
      : localStorage.setItem('token', token);
  }

  static clearToken() {
    StorageService.remove('token');
  }
}
