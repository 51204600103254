import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Key } from '../constants/key';

@Injectable({
  providedIn: 'root',
})
export class KeyService {
  constructor(private http: HttpClient) {}

  get(urlParams?) {
    return this.http.get('api-keys/', { params: urlParams }).toPromise();
  }

  post(data: Key) {
    return this.http.post('api-keys/', data).toPromise();
  }

  delete(id) {
    return this.http.delete(`api-keys/${id}/`).toPromise();
  }

  patch(id, data: Key) {
    return this.http.patch(`api-keys/${id}/`, data).toPromise();
  }
}
