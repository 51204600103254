import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { saveAs } from 'file-saver';
import { StorageService } from './storage.service';
export enum UserType {
  ALL = 'All',
  VITALITY = 'vitality',
  BRAIN = 'brain_health',
  FORTE = 'forte',
  SUBJECT = 'subject',
  QPC_ADMIN = 'qpc_admin',
  QPC_SUBJECT = 'qpc_subject',
  VRI_DEMO_admin = 'vri_demo_admin',
  VRI_DEMO_SUBJECT = 'vri_demo_subject',
  CHECKUP_CONSUMER = 'checkup_consumer',
  CHECKUP_PROXY = 'checkup_proxy',
  VRI_ONE_SUBJECT = 'vri_one_subject',
  VRI_ONE_ADMIN = 'vri_one_admin',
  CHECKUP_NAKED = 'checkup_naked'
}
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private userIdle: UserIdleService,
    private router: Router
  ) {
    this.userLocale = this.getUserLocale();
    StorageService.set('locale', this.userLocale);
    console.log('CREATED');
  }
  public defaultQuery = {
    ordering: '',
    search: '',
    page: 1,
    user_type: UserType.ALL,
    groups: null,
    limit: 10,
  };
  public requestCount = 0;
  public userLocale = '';
  public query = { ...this.defaultQuery };
  getUserLocale() {
    const userLocale = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return userLocale.includes('America') ? 'USA' : 'Europe';
  }
  getUsers() {
    return this.http.get('app-users/');
  }

  getUser(userID: number) {
    return this.http.get(`app-users/${userID}/`).toPromise();
  }

  patchUser(userID: number, body: any) {
    return this.http.patch(`app-users/${userID}/`, body).toPromise();
  }

  getUserProfiles(urlParams) {
    return this.http.get(`app-users/`, { params: urlParams }).toPromise();
  }

  removeUserAudio(id) {
    return this.http.delete(`app-users/audio/${id}/`).toPromise();
  }

  getUserProfile(userID: number) {
    return this.http.get(`userprofiles/${userID}/`);
  }

  deleteUser(userID) {
    return this.http.delete(`app-users/${userID}/`).toPromise();
  }

  createUser(user: any) {
    return this.http.post(`app-users/`, user).toPromise();
  }

  // ADMIN USERS
  getAdminUsers(urlParams?) {
    return this.http.get(`admin-users/`, { params: urlParams }).toPromise();
  }

  changeReqStatus(id: number, data: any) {
    if (data.is_confirmed == null) {
      this.requestCount += 1;
    } else {
      this.requestCount -= 1;
    }
    return this.http.put(`admin-users/${id}/confirm/`, data).toPromise();
  }

  getAdminUser(id) {
    return this.http.get(`admin-users/${id}/`).toPromise();
  }

  deleteAdminUser(id) {
    return this.http.delete(`admin-users/${id}/`).toPromise();
  }

  editAdminUser(id: number, data: any) {
    return this.http.put(`admin-users/${id}/`, data).toPromise();
  }

  createAdminUser(data: any) {
    return this.http.post(`admin-users/`, data).toPromise();
  }

  renameAudioRecording(id: number | string, newTitle: string) {
    return this.http
      .patch(`app-users/audio/${id}/`, { title: newTitle })
      .toPromise();
  }

  addTagToAudio(audioID, tags) {
    return this.http.put(`app-users/audio/${audioID}/`, { tags }).toPromise();
  }

  addTagsToAudio(audioID, tags) {
    return this.http
      .post(`app-users/audio/${audioID}/tags/`, { tags })
      .toPromise();
  }

  removeTagFromAudio(audioID, tagID) {
    return this.http
      .delete(`app-users/audio/${audioID}/tags/${tagID}/`)
      .toPromise();
  }

  adminUploadAudioToUser(userID, audio, next, title) {
    const formData = new FormData();
    formData.append('audio', audio);
    return this.http
      .post(`/audio-api/audio-ext/`, formData, {
        params: {
          UserId: userID,
          rId: localStorage.getItem('userID'),
          type: 'today',
          title,
        },
      })
      .subscribe(next);
  }

  downloadReport(userId, userName, query) {
    return new Promise((resolve, reject) => {
      let params: HttpParams = new HttpParams(query);
      for (const key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach((element) => {
            params = params.append(`${key}[]`, element);
          });
        } else params = params.append(key, query[key]);
      }
      this.http
        .request(
          new HttpRequest(
            'POST',
            `generate-report/${userId}/`,
            { ...query },
            {
              responseType: 'blob',
              reportProgress: true,
            }
          )
        )
        .subscribe(
          (event: any) => {
            if (event.type === HttpEventType.DownloadProgress) {
              //ON DownloadProgress
            }
            if (event.type === HttpEventType.UploadProgress) {
              //ON UploadProgress
            }
            if (event.type === HttpEventType.Response) {
              saveAs(event.body, `Report for id${userId} - ${userName}.zip`);
              resolve();
            }
          },
          (err) => reject(err)
        );
    });
  }

  uploadFlagCsv(csv) {
    const headers = new HttpHeaders({
      'Content-Disposition': `attachment; filename="${csv.name}" `,
    });
    return this.http
      .request(new HttpRequest('POST', `upload-flags/`, csv, { headers }))
      .toPromise();
  }

  downloadAudio(audioID, title) {
    return new Promise((resolve, reject) => {
      this.http
        .request(
          new HttpRequest('GET', `app-users/audio/${audioID}/download/`, {
            responseType: 'blob',
            reportProgress: true,
          })
        )
        .subscribe((event: any) => {
          if (event.type === HttpEventType.Response) {
            saveAs(event.body, `Recording ${audioID} ${title}`);
            resolve();
          }
        });
    });
  }
  downloadQpcData(userId, name) {
    return new Promise((resolve, reject) => {
      this.http
        .request(
          new HttpRequest('GET', `app-users/${userId}/file/download/`, {
            responseType: 'blob',
            reportProgress: true,
          })
        )
        .subscribe((event: any) => {
          if (event.type === HttpEventType.Response) {
            saveAs(event.body, `${name}`);
            resolve();
          }
        });
    });
  }
  getLanguage() {
    return this.http.get('constants/languages/').toPromise();
  }

  stopWatching() {
    console.log('stopWatching');

    this.userIdle.stopWatching();
  }

  startWatching() {

    console.log('start watching');
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => console.log(count));
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('Time is up!'); 
      this.logout()
    });
  }

  logout() {
    StorageService.set('user', '');
    StorageService.set('userID', '');
    StorageService.set('userID', '');
    StorageService.remove('flagDBColumns');
    StorageService.clearToken();
    this.stopWatching();
    this.router.navigateByUrl('/auth/login');
  }
}
