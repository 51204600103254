import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AttributesService } from '../../services/attributes.service';
import { Attribute } from '../../constants/Attribute.type';

enum STATE {
  CLOSED = 'closed',
  OPEN = 'open',
}

@Component({
  selector: 'son-attributes-manager',
  templateUrl: './attributes-manager.component.html',
  styleUrls: ['./attributes-manager.component.scss'],
})
export class AttributesManagerComponent implements OnInit {
  state: string = STATE.CLOSED;
  attributes: Attribute[];
  form: Attribute | null = null;
  isLoading: boolean = false;
  @Output() onModalClose = new EventEmitter();

  constructor(private attService: AttributesService) {}

  initAttributes = async () => {
    this.attributes = (((await this.attService.get()) as unknown) as Attribute[]).sort(
      (a, b) => a.id - b.id,
    );
  };

  toggleState = () => {
    if (this.state === STATE.OPEN) {
      this.state = STATE.CLOSED;
      this.onModalClose.emit(this.attributes);
    } else {
      this.state = STATE.OPEN;
    }
  };

  ngOnInit() {
    this.initAttributes();
  }

  saveForm = async () => {
    this.isLoading = true;
    try {
      if (this.form.id) {
        await this.attService.update(this.form);
      } else {
        await this.attService.create(this.form);
      }
      await this.initAttributes();
      this.form = null;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  edit = (att: Attribute) => {
    this.form = { ...att };
  };

  delete = async (id: number) => {
    this.isLoading = true;
    try {
      await this.attService.delete(id);
      await this.initAttributes();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  cancel = () => {
    this.form = null;
  };

  initForm = () => {
    this.form = {
      value: '',
    };
  };
}
