import { Component, Output, EventEmitter, DoCheck } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'son-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements DoCheck {
  @Output() change = new EventEmitter();

  constructor() {
    moment.locale('en');

    this.generateDates();
    this.generateCalendarDays();
    this.closeHandler = this.closeHandler.bind(this);
  }

  offset = 0;
  chosenMonth = '';
  chosenYear = '';
  calendarWeeks = [];
  days = [];
  isCalendarShown = false;

  selectedDate: any = '';

  DaysOfTheWeek = ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun'];

  listenerEnabled = false;

  ngDoCheck() {
    if (this.isCalendarShown && !this.listenerEnabled) {
      this.listenerEnabled = true;

      setTimeout(() => {
        window.addEventListener('click', this.closeHandler);
        Array.from(
          document.getElementsByClassName('datepicker-container'),
        ).forEach(element => {
          element.addEventListener('click', (event: any) =>
            event.stopPropagation(),
          );
        });
      }, 1);
    }
  }

  closeHandler() {
    this.listenerEnabled = false;
    this.isCalendarShown = false;
    window.removeEventListener('click', this.closeHandler);
    Array.from(document.getElementsByClassName('datepicker-container')).forEach(
      element => {
        element.removeEventListener('click', (event: any) =>
          event.stopPropagation(),
        );
      },
    );
  }

  private generateDates() {
    const daysCount = moment().daysInMonth();
    this.days = [];
    let i = 1;
    while (this.days.length < daysCount) {
      this.days.push(
        moment()
          .date(i)
          .format('MMM DD, YYYY'),
      );
      i += 1;
    }

    this.chosenMonth = moment().format('MMMM');
    this.chosenYear = moment().format('YYYY');
  }

  public generateNextMonth(dir: number = 1) {
    this.offset += dir;
    const date = moment()
      .add(this.offset, 'month')
      .format('MMMM YYYY');
    [this.chosenMonth, this.chosenYear] = date.split(' ');
    const daysCount = moment()
      .add(this.offset, 'month')
      .daysInMonth();
    let i = 1;
    this.days = [];
    while (this.days.length < daysCount) {
      this.days.push(
        moment()
          .date(i)
          .add(this.offset, 'month')
          .format('MMM DD, YYYY'),
      );
      i += 1;
    }
    this.generateCalendarDays();
  }

  private generateCalendarDays() {
    const month = [];
    const date = moment()
      .add(this.offset, 'month')
      .format('MMMM YYYY');
    [this.chosenMonth, this.chosenYear] = date.split(' ');

    const daysCount = moment()
      .add(this.offset, 'month')
      .daysInMonth();

    let i = 1;
    let week = [];
    while (i <= daysCount) {
      if (
        moment()
          .add(this.offset, 'month')
          .date(i)
          .day() == 1
      ) {
        month.push(week);
        week = [];
      }
      week.push({
        actualDate: moment()
          .add(this.offset, 'month')
          .date(i),
        displayValue: moment()
          .add(this.offset, 'month')
          .date(i)
          .format('DD'),
      });
      i += 1;
      i == Number(daysCount) + 1 ? month.push(week) : null;
    }
    this.fillMonth(month);
  }

  private fillMonth(month) {
    month[0].reverse();
    for (let i = 0; month[0].length < 7; i++) {
      month[0].push('');
    }
    month[0].reverse();

    for (let i = 0; month[month.length - 1].length < 7; i++) {
      month[month.length - 1].push('');
    }

    this.calendarWeeks = month.filter(week => week.filter(d => d).length);
  }

  selectDate(day) {
    this.selectedDate = moment(day.actualDate);
    this.isCalendarShown = false;
    this.listenerEnabled = false;
    window.removeEventListener('click', this.closeHandler);
    this.change.emit(day);
  }
}
