import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from '../components/header/header.component';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { DashboardTabComponent } from '../components/dashboard-tab/dashboard-tab.component';
import { AdminTableRowComponent } from '../components/admin-table-row/admin-table-row.component';
import { UserTableRowComponent } from '../components/user-table-row/user-table-row.component';
import { EmptyResultsComponent } from '../components/empty-results/empty-results.component';
import { ModalComponent } from '../components/modal/modal.component';
import { SuccessComponent } from '../components/modal/success/success.component';
import { ErrorComponent } from '../components/modal/error/error.component';
import { AccessDeniedComponent } from '../components/modal/access-denied/access-denied.component';
import { KeysTableRowComponent } from '../components/keys-table-row/keys-table-row.component';
import { RequestTableRowComponent } from '../components/request-table-row/request-table-row.component';
import { StatusRingComponent } from '../components/status-ring/status-ring.component';
import { DashboardTitleGroupComponent } from '../components/dashboard-title-group/dashboard-title-group.component';
import { UserRecordingsBlockComponent } from '../components/user-recordings-block/user-recordings-block.component';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { DatepickerComponent } from '../components/datepicker/datepicker.component';
import { StatusCircleComponent } from '../components/status-circle/status-circle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortArrowsComponent } from '../components/sort-arrows/sort-arrows.component';
import { AddTagsModalComponent } from '../components/add-tags-modal/add-tags-modal.component';
import { FileDropDirective } from '../directives/file-drop.directive';
import { FooterComponent } from '../components/footer/footer.component';
import { FilterConditionsPipe } from '../pipes/filter-conditions.pipe';
import { AttributesManagerComponent } from '../components/attributes-manager/attributes-manager.component';
import { AttributesParsePipe } from '../pipes/attributes-parse.pipe';
import { AudioRowComponent } from '../components/audio-row/audio-row.component';
import { ConditionDbComponent } from '../pages/dashboard/condition-db/condition-db.component';
import { DndComponent } from '../components/dnd/dnd.component';
import { DndModule } from 'ngx-drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CovdQuestionsComponent } from '../components/covd-questions/covd-questions.component';
import { UserMedInfoComponent } from '../components/user-med-info/user-med-info.component';
import { CrudModalComponent } from '../components/crud-modal/crud-modal.component';
import { FilterMetaPipe } from '../pipes/filter-meta.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { OverRulesComponent } from '../components/over-rules/over-rules.component';
import { CovidSymptomsComponent } from '../components/covid-symptoms/covid-symptoms.component';
import { LimitPaginationComponent } from '../components/limit-pagination/limit-pagination.component';
import { FormatFlagRowPipe } from '../pipes/format-flag-row.pipe';
import { UniqueStringsPipe } from '../pipes/unique-strings.pipe';

// MODULE FOR PIPES, DIRECTIVES, SHARED COMPONENTS, ETC
// NO PROVIDERS HERE

//  ADD TO DECLARATIONS AND EXPORTS
@NgModule({
  declarations: [
    HeaderComponent,
    DashboardTabComponent,
    AdminTableRowComponent,
    UserTableRowComponent,
    ModalComponent,
    SuccessComponent,
    ErrorComponent,
    AccessDeniedComponent,
    KeysTableRowComponent,
    EmptyResultsComponent,
    RequestTableRowComponent,
    StatusRingComponent,
    DashboardTitleGroupComponent,
    UserRecordingsBlockComponent,
    DateFormatPipe,
    DatepickerComponent,
    StatusCircleComponent,
    SortArrowsComponent,
    AddTagsModalComponent,
    FileDropDirective,
    FooterComponent,
    FilterConditionsPipe,
    AttributesManagerComponent,
    AttributesParsePipe,
    AudioRowComponent,
    ConditionDbComponent,
    DndComponent,
    CovdQuestionsComponent,
    UserMedInfoComponent,
    CrudModalComponent,
    FilterMetaPipe,
    OverRulesComponent,
    CovidSymptomsComponent,
    LimitPaginationComponent,
    FormatFlagRowPipe,
    UniqueStringsPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgbModule,
    ToastrModule.forRoot(),
    DndModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
  ],
  exports: [
    HeaderComponent,
    UserMedInfoComponent,
    CovdQuestionsComponent,
    DashboardTabComponent,
    AdminTableRowComponent,
    KeysTableRowComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    UserTableRowComponent,
    ModalComponent,
    SuccessComponent,
    ErrorComponent,
    AccessDeniedComponent,
    EmptyResultsComponent,
    RequestTableRowComponent,
    StatusRingComponent,
    DashboardTitleGroupComponent,
    UserRecordingsBlockComponent,
    DateFormatPipe,
    DatepickerComponent,
    StatusCircleComponent,
    SortArrowsComponent,
    AddTagsModalComponent,
    FileDropDirective,
    NgbModule,
    FooterComponent,
    FilterConditionsPipe,
    AttributesManagerComponent,
    AttributesParsePipe,
    AudioRowComponent,
    ConditionDbComponent,
    DndComponent,
    CrudModalComponent,
    FilterMetaPipe,
    OverRulesComponent,
    CovidSymptomsComponent,
    LimitPaginationComponent,
    FormatFlagRowPipe,
    UniqueStringsPipe,
  ],
})
export class CoreModule {}
