import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueStrings',
})
export class UniqueStringsPipe implements PipeTransform {
  transform(values: string[]): string[] {
    return Array.from(new Set(values));
  }
}
