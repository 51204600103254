import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'son-dashboard-tab',
  templateUrl: './dashboard-tab.component.html',
})
export class DashboardTabComponent implements OnInit {
  @Input() to = '/';

  constructor() {}

  ngOnInit() {}
}
