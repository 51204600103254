import { toCamelCase } from '../helper/toCamelCase';
import { CategoryService } from '../services/category.service';
import uniqBy from 'lodash/uniqBy';

const keysForTextValue = [
  'correlation',
  'fundamental',
  'recommendation',
  'recommendation_if_high',
  'recommendation_if_low'
];
const keysForRules = [
  'fundamental',
  'recommendation',
  'recommendation_if_high',
  'recommendation_if_low'
];
function formatCategoriesAndSubCategories(db) {
  const keys = [
    'correlation',
    'category',
    'subcategory',
    'flag_category',
    'flag_direction',
    'fundamental',
    'recommendation',
    'recommendation_if_high',
    'recommendation_if_low'
  ];

  keys.forEach(key => {
    if (db[key]) {
      const categories = db[key]
        .filter((e: string) => e)
        .map((e: string) => toCamelCase(e.trim()));

      db[key] = uniqArray(categories);
      db[key].sort(alphabeticallySort);
    }
  });

  return db;
}

function uniqArray(arr) {
  return Array.from(new Set(arr));
}

function alphabeticallySort(a, b) {
  return a.localeCompare(b, undefined, {
    sensitivity: 'accent',
    caseFirst: 'lower'
  });
}
function metaFormat(data) {
  data.sort((a, b) => alphabeticallySort(a.field_text, b.field_text));

  const formattedData = data.reduce((acc, e) => {
    const dataToFormat = { ...acc };
    const fieldType = e.field_type;
    return {
      ...dataToFormat,
      [fieldType]: dataToFormat[fieldType]
        ? [...dataToFormat[fieldType], e]
        : [e]
    };
  }, {});
  return formattedData;
}
function metaFormatToStr(data) {
  const newData = metaFormat(data);
  for (const key in newData) {
    if (newData.hasOwnProperty(key)) {
      const element = newData[key];
      newData[key] = newData[key].map(e => e.field_text);
    }
  }
  return newData;
}
function checkIfNeedToCreateNewMeta(dbValues, meta) {
  const normalizedMeta = normalizeMeta(meta);

  const needTocreate = [];

  const oldData = keysForTextValue
    .map(key => {
      const dbValue: string = dbValues[key];
      const metaArr = normalizedMeta[key];
      if (!dbValue) {
        return {
          [key]: null
        };
      }
      const existingMeta = metaArr.find(
        e => e.field_text == dbValue.toLowerCase().trim()
      );
      if (existingMeta) {
        return {
          field_type: key,
          field_text: existingMeta.field_text,
          id: existingMeta.id
        };
      } else {
        needTocreate.push({
          field_text: dbValue,
          field_type: key
        });
      }
    })
    .filter(e => e);

  return {
    oldData,
    needTocreate
  };
}
function normalizeMeta(meta) {
  return keysForTextValue.reduce((acc, key) => {
    const metaArr = meta[key];
    const normalizedArr = metaArr.map(e => {
      const normalizedValue = (e.field_text as string).toLowerCase().trim();
      return {
        ...e,
        field_text: normalizedValue
      };
    });
    return { ...acc, [key]: normalizedArr };
  }, {});
}
function formatMetaForRules(rule, meta) {
  const normalizedMeta = normalizeMeta(meta);
  const needTocreate = [];
  const keysForTextValue = [
    'fundamental',
    'recommendation',
    'recommendation_if_high',
    'recommendation_if_low'
  ];
  const oldData = keysForTextValue
    .map(key => {
      const dbValue: string = rule[key].field_text;

      const metaArr = normalizedMeta[key];
      if (!dbValue) {
        return {
          [key]: null
        };
      }
      const existingMeta = metaArr.find(
        e => e.field_text == dbValue.toLowerCase().trim()
      );
      if (existingMeta) {
        console.log(existingMeta);
        return {
          field_type: key,
          field_text: existingMeta.field_text,
          id: existingMeta.id
        };
      } else {
        needTocreate.push({
          field_text: dbValue,
          field_type: key
        });
      }
    })
    .filter(e => e);

  return {
    oldData,
    needTocreate
  };
}
function prepareMeta(needTocreate, rulesMeta) {
  function formater(e) {
    return { ...e, uniqKey: `${e.field_text}_${e.field_type}` };
  }
  const allFieldWhichNeedToCreate = [
    ...rulesMeta.reduce(
      (acc, rule) => [...acc, ...rule.needTocreate.map(formater)],
      []
    ),
    ...needTocreate.map(formater)
  ];
  const allWhichExist = rulesMeta.reduce(
    (acc, rule) => [...acc, ...rule.oldData.map(formater)],
    []
  );

  const uniqNeedToCreate = allFieldWhichNeedToCreate.length
    ? uniqBy(allFieldWhichNeedToCreate, 'uniqKey')
    : [];
  console.log(uniqNeedToCreate);

  return { allFieldWhichNeedToCreate, allWhichExist, uniqNeedToCreate };
}
function prepareMetaForDB(createdMeta, needTocreate, oldData) {
  const metaForDB = createdMeta.filter(e =>
    needTocreate.find(
      meta =>
        meta.field_type == e.field_type &&
        normalize(meta.field_text) == normalize(e.field_text)
    )
  );
  console.log(createdMeta), console.log(needTocreate);
  return [...oldData, ...metaForDB].reduce((acc, e) => {
    if (e.field_type) {
      return {
        ...acc,
        [e.field_type]: e.id
      };
    } else {
      return {
        ...acc,
        ...e
      };
    }
  }, {});
}
function normalize(str: string) {
  return str.toLowerCase().trim();
}
export default {
  formatCategoriesAndSubCategories,
  uniqArray,
  alphabeticallySort,
  metaFormat,
  metaFormatToStr,
  checkIfNeedToCreateNewMeta,
  formatMetaForRules,
  keysForRules,
  prepareMeta,
  prepareMetaForDB,
  normalize
};
