import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Group} from '../constants/groups';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private http: HttpClient) {
  }

  get(query) {
    return this.http.get('group/', {params: query}).toPromise();
  }

  getById(id) {
    return this.http.get(`group/${id}/`).toPromise();
  }

  post(group) {
    return this.http.post(`group/`, group).toPromise();
  }

  patch(id, group) {
    return this.http.patch(`group/${id}/`, group).toPromise();
  }

  delete(id) {
    return this.http.delete(`group/${id}/`).toPromise();
  }

}
