import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('audio-api')) {
      const apiReq = req.clone(
        {
          url: req.url.replace('/audio-api/', environment.mobApi),
        } /*{ url: `/${req.url}` }*/,
        );
        return next.handle(apiReq);
      }
    if (req.url.includes('ip-api')) return next.handle(req);
    const apiReq = req.clone(
      { url: `${environment.baseUrl}/${req.url}` } /*{ url: `/${req.url}` }*/,
    );
    return next.handle(apiReq);
  }
}
