import { Pipe, PipeTransform } from '@angular/core';
import { Attribute } from '../constants/Attribute.type';

@Pipe({
  name: 'attributesParse',
})
export class AttributesParsePipe implements PipeTransform {
  transform(attrs: Attribute[]): string[] {
    return attrs.map(a => a.value);
  }
}
