import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userLoggedIn =
      StorageService.token() &&
      StorageService.get('user') &&
      StorageService.get('userID');

    if (userLoggedIn) return true;
    this.router.navigate(['/auth/login'], {
      queryParams: {
        next: state.url,
      },
    });
    return false;
  }
}
