import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMeta'
})
export class FilterMetaPipe implements PipeTransform {

  transform(value: any[], search: any): any {
    if (!search) return value;
    const normalizedSearch = (search as string).toLocaleLowerCase();
    return value.filter((e) => {
      const normalizedValue = e.field_text.toLocaleLowerCase();
      return normalizedValue.includes(normalizedSearch);
    })
  }

}
