import { StorageService } from '../services/storage.service';
export class Role {
  private static user = StorageService.get('user');

  static get isAdmin(): Boolean {
    return this._checkGroup(1);
  }
  static get isResearcher(): Boolean {
    return this._checkGroup(2);
  }
  static get isB2b(): Boolean {
    return this._checkGroup(3);
  }
  static get hasModeratorPermissions(): Boolean {
    return this.user.is_moderator;
  }

  private static _checkGroup(id) {
    this.user = StorageService.get('user');
    return this.user && this.user.groups.some(e => e.id == id);
  }
}
