import { Component, Input } from '@angular/core';

@Component({
  selector: 'son-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss'],
})
export class StatusCircleComponent {
  @Input() circleValue: number;
}
