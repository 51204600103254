import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Audio} from '../../constants/Audio.type';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'son-audio-row',
    templateUrl: './audio-row.component.html',
    styleUrls: ['./audio-row.component.scss']
})
export class AudioRowComponent {
    @Input() audio: Audio;
    @Input() configDb: string;
    @Input() flagDb: string;
    @Input() dbs: any[];
    @Input() loader: boolean;
    @Input() hide_csv_data_in_pdf: boolean = false;
    @Input() show_recommendations: boolean = false;

    @Input() checkboxes: {
        category: [];
        subcategory: [];
    };

    @Output() onRecordingDownload = new EventEmitter();
    @Output() onReportDownload = new EventEmitter();
    @Output() onAddTags = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() removeTagFromAudio = new EventEmitter();
    @Output() selectDb = new EventEmitter();
    @Output() onRename = new EventEmitter();

    
    selectedCategories: any = {};
    selectedSubCategories: any = {};
    orderByCategory: string = '';
    textOrderBy: string = 'alpha';
    showDbBlock: boolean

    orderByCategories = [
        {
            title: 'Priority',
            value: 'priority'
        },
        {
            title: 'BWH Low to High',
            value: '-BWH'
        },
        {
            title: 'BWH High to Low',
            value: 'BWH'
        },
        {
            title: 'Alphabetical',
            value: 'alpha'
        },
        {
            title: 'Decibel level',
            value: 'dbl'
        }
    ];

    orderTextBy = [
        {
            title: 'Alphabetical',
            value: 'alpha',
        },
        {
            title: 'Category',
            value: 'category',
        },
        {
            title: 'Subcategory',
            value: 'subcategory',
        },
        {
            title: 'Fundamental',
            value: 'fundamental',
        },
        {
            title: 'Vitality Indicator',
            value: 'flag_category',
        },
    ];

    csvSettings = [
        {
            title: 'Only CSV',
            value: 'ONLY_CSV'
        },
        {
            title: 'With CSV',
            value: 'WITH_CSV'
        },
        {
            title: 'Without CSV',
            value: 'WITHOUT_CSV'
        }
    ];

    selectCsvSettings: string = '';
    selectedDb: string = '';

    get isCheckedCategories() {
        const checked = Object.keys(this.selectedCategories).filter((key) => {
            return this.selectedCategories[key] === true;
        });

        return (checked.length > 0);
    }

    get isCheckedSubCategories() {
        const checked = Object.keys(this.selectedSubCategories).filter((key) => {
            return this.selectedSubCategories[key] === true;
        });

        return (checked.length > 0);
    }

    ngOnChanges(changes): void {
        if (changes.checkboxes && changes.checkboxes.currentValue) {
            this.selectedCategories = {};
            this.selectedSubCategories = {};

            this.orderByCategory = '';
        }
        if (changes.loader && changes.loader.currentValue) {
            this.loader = changes.loader.currentValue;
        }
        if (changes.audio && changes.audio.currentValue) {
            this.audio.title = this.audio.title || 'Recording ' + this.audio.id;
            this.audio.newTitle = this.audio.title;
        }
    }

    constructor(private userService: UserService) {
    }

    async rename(newTitle: string) {
        if (!newTitle || newTitle == this.audio.title) {
            return;
        }
        await this.userService.renameAudioRecording(this.audio.id, newTitle);
        this.audio.title = newTitle;

        this.onRename.emit(newTitle);
    }

    shouldShowCreated(db) {
        return db.report_caches.some(e => e.audio_id == this.audio.id);
    }

    getCreated(db) {
        const index = db.report_caches.findIndex(e => e.audio_id === this.audio.id);
        return index == -1 ? '' : db.report_caches[index].created;
    }

    generateReport() {
        const categories = [];
        const subcategories = [];

        for (const key in this.selectedCategories) {
            if (this.selectedCategories.hasOwnProperty(key)) {
                if (this.selectedCategories[key]) {
                    categories.push(key);
                }
            }
        }
        for (const key in this.selectedSubCategories) {
            if (this.selectedSubCategories.hasOwnProperty(key)) {
                if (this.selectedSubCategories[key]) {
                    subcategories.push(key);
                }
            }
        }
        const query = {
            db: this.selectedDb,
            audio: this.audio.id,
            categories,
            subcategories,
            orderByCategory: this.orderByCategory || 'alpha',
            textOrderBy: this.textOrderBy || 'alpha',
            csvSetting: 'WITH_CSV',
            hide_csv_data_in_pdf: this.hide_csv_data_in_pdf,
            show_recommendations: this.show_recommendations
        };
        this.onReportDownload.emit(query);
    }

    selectAllCat($event) {
        this.checkboxes.category.map(e => {
            this.selectedCategories[e] = $event.target.checked;
        });

        if (this.audio.hiddenCategories) {
            this.audio.hiddenCategories.map(e => {
                this.selectedCategories[e] = $event.target.checked;
            });
        } else {
            this.audio.hiddenCategories = [];
        }
        
    }

    selectAllSubCat($event) {
        this.checkboxes.subcategory.map(e => {
            this.selectedSubCategories[e] = $event.target.checked;
        });
    }

    categoriesChanged(cat) {

        if (cat === 'Muscle') {
            if (this.audio.hiddenCategories) {
                this.audio.hiddenCategories.map(e => {
                    this.selectedCategories[e] = this.selectedCategories[cat];
                });
            } else {
                this.audio.hiddenCategories = [];
            }
            
        }
    }
}
