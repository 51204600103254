import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/constants/user';

@Component({
  selector: 'son-user-med-info',
  templateUrl: './user-med-info.component.html',
  styleUrls: ['./user-med-info.component.scss']
})
export class UserMedInfoComponent implements OnInit {

  constructor() { }
  @Input() userData: User
  ngOnInit() {
  }

}
