import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { isNumber } from 'util';
enum STATE {
  CLOSED = 'closed',
  OPEN = 'open'
}
@Component({
  selector: 'son-crud-modal',
  templateUrl: './crud-modal.component.html',
  styleUrls: ['./crud-modal.component.scss']
})
export class CrudModalComponent implements OnInit, OnDestroy {
  constructor(private categoryService: CategoryService) {}
  @Output() onModalClose = new EventEmitter();
  @Output() onRemoveData = new EventEmitter();
  @Output() onSaveData = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Input() showEditPencil = true;

  @Input() fieldType: string;
  @Input() data: any;
  search: string;
  newItem: string;
  submitted: boolean;
  state: string = STATE.CLOSED;
  itemForRemove: any;
  deleteModal: boolean;
  ngOnInit() {}
  ngOnDestroy() {
    this.search = '';
  }
  async addNew() {
    this.submitted = true;
    if (!this.newItem) return;
    try {
      const newItem = await this.categoryService.postFieldMeta({
        field_text: this.newItem,
        field_type: this.data[0].field_type
      });
      this.newItem = '';
      this.data = [newItem, ...this.data];
      this.onSaveData.emit({item: newItem, type: this.data[0].field_type});
      
    } catch (e) {
    } finally {
      this.submitted = false;
    }
  }
  toggleState = () => {
    if (this.state === STATE.OPEN) {
      this.state = STATE.CLOSED;
      this.search = '';
      this.newItem = '';
      this.submitted = false;
      this.onModalClose.emit(); 
    } else {
      this.state = STATE.OPEN;
    }
  };
  prepareForRemove(item, index) {
    this.itemForRemove = { ...item, index };
    if (item.is_used) {
      this.deleteModal = true;
    } else {
      this.removeMeta();
    }
  }
  closeDeleteModal() {
    this.deleteModal = false;
    this.itemForRemove = null;
  }
  async removeMeta() {
    const data = await this.categoryService.deleteMeta(this.itemForRemove.id);
    const tempArr = [...this.data];
    const index = tempArr.findIndex(e => e.id == this.itemForRemove.id);
    tempArr.splice(index, 1);
    this.data = [...tempArr];
    this.onRemoveData.emit({
      id: this.itemForRemove.id,
      type: this.itemForRemove.field_type
    });
    this.closeDeleteModal();
  }
  async edit($event, item) {
    const newInputExist = $event.target.value;
    const newEqualOld = item.field_text == $event.target.value;
    if (!newInputExist || newEqualOld) return;
    const updatedItem = await this.categoryService.putMeta(item.id, {
      ...item,
      field_text: $event.target.value
    });
    item.updated = true;
    setTimeout(() => {
      item.updated = false
      this.onEdit.emit(updatedItem);
    } , 2000);
  }
}
