import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'son-empty-results',
  templateUrl: './empty-results.component.html',
  styleUrls: ['./empty-results.component.scss'],
})
export class EmptyResultsComponent implements OnInit {
  @Input() titleText: string;

  constructor() {
  }

  ngOnInit() {
  }

}
