import { Component, OnInit, Input, Output } from '@angular/core';
import { TitlesService } from 'src/app/services/titles.service';
import { ToastrService } from 'ngx-toastr';
import { debounce } from 'lodash';
import { PopUpService } from '../../services/pop-up.service';

@Component({
  selector: 'son-dashboard-title-group',
  templateUrl: './dashboard-title-group.component.html',
  styleUrls: ['./dashboard-title-group.component.scss'],
})
export class DashboardTitleGroupComponent implements OnInit {

  constructor(
    private titleService: TitlesService,
    private toast: ToastrService,
    private popUp: PopUpService,
  ) {
    this.saveTitle = debounce(this.saveTitle, 700);
  }
  @Input() greetingGroup: any;

  titleTypeToAdd = null;

  ngOnInit() {
  }

  async clearTitle(gr) {
    await this.titleService.delete(gr.id);
    this.greetingGroup.docs = this.greetingGroup.docs.filter(
      d => d.id != gr.id,
    );
    this.popUp.showPopUp('success', 'Title was deleted successfully!');
  }

  async addTitle(value) {
    const addedGroup = await this.titleService.create(
      this.titleTypeToAdd.toUpperCase(),
      value,
    );
    this.popUp.showPopUp('success', 'Title was created successfully!');
    this.greetingGroup.docs.push(addedGroup);
    this.titleTypeToAdd = null;
  }

  async saveTitle(gr) {
    await this.titleService.update(gr.id, gr);
    this.popUp.showPopUp('success', 'Title was updated successfully!');
  }
}
