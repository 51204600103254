import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'son-success',
  templateUrl: './success.component.html',
})
export class SuccessComponent implements OnInit {
  @Output() dismiss = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
