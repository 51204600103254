import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Rule from '../constants/rules.types';
import utils from '../utils/FormatCategoriesToFilter';

@Injectable({
  providedIn: 'root'
})
export class RuleService {
  rules: Rule[];
  rulesBeforeSave: Rule[];

  constructor(private http: HttpClient) {
    this.rules = [];
    this.rulesBeforeSave = [];
  }

  getByRowID(rowID): Promise<Rule[]> {
    return this.http
      .get(`constants/app_metadata/`, {
        params: {
          flags_db_row_id: rowID
        }
      })
      .toPromise() as Promise<Rule[]>;
  }
  deleteRule(id) {
    return this.http
      .delete(`constants/app_metadata/${id}/`)
      .toPromise() as Promise<Rule[]>;
  }
  createRule(rule) {
    return this.http
      .post(`constants/app_metadata/`, rule)
      .toPromise() as Promise<Rule[]>;
  }
  prepareRules(createdMeta, allWhichExist) {
    return this.rules.map(rule => {
      utils.keysForRules.forEach(key => {
        const metaFinder = meta => {
          return (
            meta.field_type == key &&
            utils.normalize(meta.field_text) ==
              utils.normalize(rule[key].field_text)
          );
        };
        const metaIsUsed = createdMeta.find(metaFinder);
        console.log(metaIsUsed);
        if (metaIsUsed) return (rule[key] = metaIsUsed);
        const metaAlreadyCreated = allWhichExist.find(metaFinder);
        console.log(metaAlreadyCreated);
        if (metaAlreadyCreated) return (rule[key] = metaAlreadyCreated);
      });
      return rule;
    });
  }
  async saveRules(rules, rowID) {
    await Promise.all(
      this.rulesBeforeSave
        .filter(e => e.id)
        .map(e => {
          return this.deleteRule(e.id);
        })
    );
    await Promise.all(
      rules.map(elem => {
        const formatedRule = {
          app: elem.app.id,
          fundamental: elem.fundamental.id,
          flags_db_row: elem.flags_db_row.id,
          recommendation: elem.recommendation.id,
          recommendation_if_low: elem.recommendation_if_low.id,
          recommendation_if_high: elem.recommendation_if_high.id
        };
        return this.createRule(formatedRule);
      })
    );
  }
  formatRules(rules) {
    return rules.map(elem => {
      const formatedRule = {
        app: elem.app.id,
        flags_db_row: elem.flags_db_row.id,
        fundamental: this.helperForFormat(elem.fundamental),
        recommendation: this.helperForFormat(elem.recommendation),
        recommendation_if_low: this.helperForFormat(elem.recommendation_if_low),
        recommendation_if_high: this.helperForFormat(
          elem.recommendation_if_high
        )
      };
      return formatedRule;
    });
  }
  private helperForFormat(object) {
    return object.field_text ? object.id : null;
  }
  private removeBlankField(rule) {
    for (const key in rule) {
      if (rule.hasOwnProperty(key)) {
        const element = rule[key];
        if (!element) {
          delete rule[key];
        }
      }
    }
    return rule;
  }
}
