import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'son-user-recordings-block',
  templateUrl: './user-recordings-block.component.html',
  styleUrls: ['./user-recordings-block.component.scss'],
})
export class UserRecordingsBlockComponent implements OnInit {
  constructor() {}

  isOpen = false;

  ngOnInit() {}
}
