import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigDbService {
  constructor(private http: HttpClient) {}

  get(params?) {
    return this.http.get('constants/configuration/', { params }).toPromise();
  }

  getDB(id: number) {
    return this.http.get(`constants/configuration/${id}/`).toPromise();
  }

  patch(id, configuration) {
    return this.http
      .patch(`constants/configuration/${id}/`, configuration)
      .toPromise();
  }

  post(configuration) {
    return this.http
      .post(`constants/configuration/`, configuration)
      .toPromise();
  }

  delete(id) {
    return this.http.delete(`constants/configuration/${id}/`).toPromise();
  }

  saveConfigurationDBtoNewVersion(version) {
    return this.http
      .post(`constants/configuration/version/`, version)
      .toPromise();
  }

  updateConfigDBVersion(id, version) {
    return this.http
      .patch(`constants/configuration/version/${id}/`, version)
      .toPromise();
  }

  changeActiveVersion(configurationID, selected_version) {
    return this.http
      .put(`constants/configuration/${configurationID}/`, { selected_version })
      .toPromise();
  }
}
