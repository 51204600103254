import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'son-limit-pagination',
  templateUrl: './limit-pagination.component.html',
  styleUrls: ['./limit-pagination.component.scss']
})
export class LimitPaginationComponent implements OnInit, OnChanges {
  allOption = { title: 'All', value: 999 };
  defaultOption = [
    { title: 10, value: 10 },
    { title: 25, value: 25 },

    { title: 50, value: 50 },
    { title: 100, value: 100 }
  ];
  options = [];
  @Input() query = {};
  @Input() totalItems = 999;

  @Output() onChange = new EventEmitter<number | string>();

  constructor() {
    this.generateOptions();
  }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.totalItems &&
      changes.totalItems.currentValue != changes.totalItems.previousValue
    ) {
      this.allOption.value = Number(changes.totalItems.currentValue);
      this.generateOptions();
    }
  }
  generateOptions() {
    this.options = [...this.defaultOption, this.allOption];
  }
}
