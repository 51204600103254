import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import { TagsService } from '../../services/tags.service';
import { debounce } from 'lodash';
import { ToastrService } from 'ngx-toastr';
interface Tag {
  value: string;
  id: number;
}
@Component({
  selector: 'son-add-tags-modal',
  templateUrl: './add-tags-modal.component.html',
  styleUrls: ['./add-tags-modal.component.scss'],
})
export class AddTagsModalComponent implements OnInit {
  @Input() recordID: string;
  @Input() modalVisible: boolean;
  @Input() modalMode: string; 
  @Output() toggledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onTagsAdded: EventEmitter<Tag[]> = new EventEmitter<Tag[]>();
  @Input() existedTags: Tag[];

  tags: Tag[] = [];
  maxTagsCount: number = 10;
  availableTags: Tag[] = [];
  newTag: Tag = { value: '', id: null };
  showDropDown: boolean = false;

  constructor(
    private userService: UserService,
    private tagsService: TagsService,
    private toast: ToastrService,
  ) {
    this.tagSearch = debounce(this.tagSearch, 300);
  }

  ngOnInit() {}
  async tagSearch() {
    this.availableTags = (await this.tagsService.get({
      search: this.newTag.value,
    })) as Tag[];
    this.showDropDown = true;
  }
  async addNewTag() {
    if (!this.newTag.id)
      this.newTag = (await this.tagsService.create(this.newTag)) as Tag;
    if (
      this.tags.some(e => e.id == this.newTag.id) ||
      this.existedTags.some(e => e.id == this.newTag.id)
    ) {
      this.toast.info('Tag has been already added!');
      this.newTag = { value: '', id: null };
      return;
    }

    this.tags.push(this.newTag);
    this.newTag = { value: '', id: null };
  }
  selectTag(tag: Tag) {
    this.newTag = tag;
    this.showDropDown = false;
    this.addNewTag();
  }
  closeAddTagModal() {
    this.modalVisible = false;
    this.clearTagInput();
    this.toggledChange.emit(this.modalVisible);
  }

  clearTagInput() {
    this.newTag = { value: '', id: null };
  }
  async saveTags() {
    if (!this.tags.length) {
      this.toast.error('You should add at least one tag!');
      return;
    }
    if (this.modalMode != 'TO_FILTER') {
      const resp = await this.userService.addTagsToAudio(
        this.recordID,
        this.tags.map(e => e.id),
      );
    }
    this.onTagsAdded.emit(this.tags);
    this.closeAddTagModal();
  }
  removeTag(index) {
    this.tags.splice(index, 1);
  }
}
