import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'son-access-denied',
  templateUrl: './access-denied.component.html',
})
export class AccessDeniedComponent implements OnInit {
  @Output() dismiss = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
