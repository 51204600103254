import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './storage.service';
import { PopUpService } from './pop-up.service';
import { isArrayOfObjects, toErrorFormat } from '../helper/toErrorFormat';

@Injectable({ providedIn: 'root' })
export class HttperrorsInterceptor implements HttpInterceptor {
  constructor(
    private toast: ToastrService,
    private router: Router,
    private popUpService: PopUpService
  ) {}

  messageByStatus = {
    defaultMessage: 'Unknown Error',
    '0': 'Connection error or server disconnected',
    '401': 'Unauthorized',
    '403': 'Forbidden',
  };

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const ERR_MES_FOR_DB = 'This revision is already accepted/rejected';
        const message =
          error && error.error.reason
            ? error.error.reason
            : error.message || 'Unknown Error';

        if (this.messageByStatus.hasOwnProperty(error.status)) {
          error['textError'] = this.messageByStatus[error.status];
        }
        console.log();
        switch (error.status) {
          case 401:
            StorageService.clearToken();
            this.router.navigate(['/auth/login']);
            break;
          case 403:
            console.log(error);
            if (error.error.code === 'PWD_EXPIRED') {
              if (error.error.token) {
                StorageService.setToken(error.error.token, false);
                console.log('token');
              }
              this.router.navigate(['/auth/password-reset/expired']);
              return;
            }
            this.popUpService.showPopUp(
              error?.error?.detail == ERR_MES_FOR_DB
                ? 'error'
                : 'permissionDenied',
              error?.error?.detail == ERR_MES_FOR_DB
                ? ERR_MES_FOR_DB
                : 'You can request access from the administrator'
            );
            break;
          case 404:
            this.popUpService.showPopUp(
              'error',
              'Selected item does not exist'
            );
            break;
          case 400:
            if (error.error.is_moderator) {
              this.popUpService.showPopUp(
                'error',
                "To get a moderator permissions, contact the administrator with the moderator's permissions"
              );
            }
            break;
          default:
        }

        for (const key in error.error) {
          if (error.error.hasOwnProperty(key)) {
            const element = error.error[key];
            if (
              typeof element === 'object' &&
              Array.isArray([element]) &&
              !isArrayOfObjects(element)
            ) {
              element.forEach((e) => {
                if ('No changes found, index 0' !== e) {
                  this.popUpService.showPopUp('error', e);
                }
              });
            } else if (
              typeof element === 'object' &&
              Array.isArray([element]) &&
              isArrayOfObjects(element)
            ) {
              const errosList = toErrorFormat(error.error);
              const errorField =
                Object.keys(errosList[0])[0] || Object.keys(errosList[1])[0];
              let message = '';
              if (errorField.includes('non_field_errors')) {
                message = [errosList[0][errorField]].join(': ');
              }
              if (errorField.includes('app')) {
                message = 'Please choose app in override rules';
              }
              if (
                !errorField.includes('non_field_errors') &&
                !errorField.includes('app')
              ) {
                message = [errorField, errosList[0][errorField]].join(': ');
              }
              this.popUpService.showPopUp('error', message);
            }
          }
        }
        return throwError(error);
      })
    );
  }
}
