import { Pipe, PipeTransform } from '@angular/core';

const n = val => (val ? val.toString().toLowerCase() : '');

@Pipe({
  name: 'filterConditions',
})
export class FilterConditionsPipe implements PipeTransform {
  transform(value: Array<any>, searchObj, dbs): any {
    let filtered = dbs;
    if (Object.values(searchObj).filter(v => v).length) {
      // tslint:disable-next-line: forin
      for (const key in searchObj) {
        filtered = filtered.filter(row => {
          if (Array.isArray (searchObj[key])) {

            // every for AND some for OR
            return searchObj[key].every( e =>  n(row[key]).includes(n(e)), )
          } else {
            return n(row[key]).includes(n(searchObj[key]));
          }
          }
        );
      }
      return filtered;
    }
    return value;
  }
}
