import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopUpService {
  public popUps = new BehaviorSubject<any>(defaultState);

  constructor() {
    this.popUps.next({type: 'test', message: 'sad'});
    setTimeout(() => {
      this.popUps.next({type: 'test2', message: 'sdddd'});
    }, 3000);
  }

  showPopUp = (type, message) => {
    this.popUps.next({type, message});
  };

  close() {
    this.popUps.next({type: null, message: ''});
  }
}

const defaultState = {
  type: null,
  message: '',
};
