import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/helper/Role';
import { UserService } from 'src/app/services/user.service';
import { FlagsDbService } from '../../../services/flags-db.service';
import { CategoryService } from '../../../services/category.service';
import { FIELDS } from './constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../../services/storage.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PopUpService } from 'src/app/services/pop-up.service';
import utils from '../../../utils/FormatCategoriesToFilter';
import { RuleService } from 'src/app/services/rules.service';
@Component({
  selector: 'son-condition-db',
  templateUrl: './condition-db.component.html',
  styleUrls: ['./condition-db.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConditionDbComponent implements OnInit, OnDestroy {
  @ViewChild('editItem', { static: true }) editItem: ElementRef;

  role = Role;
  meta = {
    correlation: [],
    category: [],
    subcategory: [],
    flag_category: [],
    flag_direction: [],
    fundamental: [],
    recommendation: [],
    recommendation_if_high: [],
    recommendation_if_low: []
  };
  metaSelected = {
    correlation: [],
    category: [],
    subcategory: [],
    flag_category: [],
    flag_direction: [],
    fundamental: [],
    recommendation: [],
    recommendation_if_high: [],
    recommendation_if_low: []
  };
  query: any = {};
  results: any;
  totalPages = 0;
  totalItems = 1;

  loaded: boolean = false;
  fields: any;

  tempDB: any;
  toDelete: any = null;
  // info btn on pop ups

  deleteModal = false;
  deleteFlagsDbModal = false;
  isDeleteErrorModalShown = false;

  ordering: string;
  configBDForm: FormGroup;
  dropdownSettings: IDropdownSettings;
  dropdownSettingsSingle: IDropdownSettings;

  formReady = false;
  submitted = false;
  mode: string;
  editItemId: number = null;
  itemToEdit = null; // need for check diff
  sendToModeration = false;
  changed = false;
  ruleChanged = false;

  showSuggestionCat: boolean = false;
  showSuggestionSub: boolean = false;
  showSuggestionFlag: boolean = false;

  apps: any[] = [];

  alertModal: boolean = false;
  public redirectUrl: string = '';

  constructor(
    private router: Router,
    private userService: UserService,
    private flagsDbService: FlagsDbService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private popUpService: PopUpService,
    private rs: RuleService
  ) {
    this.query = {
      limit: 10,
      ordering: 'correlation',
      overrides: 'All',
      search: FIELDS.reduce((acc, e) => {
        acc[e.name] = '';
        return acc;
      }, {} as any)
    };
  }

  ngOnDestroy() {
    this.fields = undefined;
  }

  setFlagDBColumns(list) {
    StorageService.set('flagDBColumns', list, true);
  }

  get _fields() {
    let _fields = StorageService.get('flagDBColumns', true);
    if (!_fields) {
      StorageService.set('flagDBColumns', FIELDS, true);
      _fields = StorageService.get('flagDBColumns', true);
    }
    return _fields;
  }

  async ngOnInit() {
    this.fields = this._fields;

    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'field_text'
    };
    this.dropdownSettingsSingle = {
      singleSelection: true,
      enableCheckAll: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'field_text'
    };
    this.apps = await this.flagsDbService.getAppList();
    const [db, meta] = await Promise.all([this.getDBs(), this.getAllMeta()]);
  }
  get f() {
    return this.configBDForm.controls;
  }
  getAllMeta() {
    return Promise.all([
      this.getMeta({ field_type: 'correlation', is_used: true }),
      this.getMeta({ field_type: 'fundamental' }),
      this.getMeta({ field_type: 'recommendation_if_high' }),
      this.getMeta({ field_type: 'recommendation' }),
      this.getMeta({ field_type: 'recommendation_if_low' }),
      this.getMeta({ field_type: 'category' }),
      this.getMeta({ field_type: 'subcategory' }),
      this.getMeta({ field_type: 'flag_category' }),
      this.getMeta({ field_type: 'flag_direction' })
    ]);
  }
  patchForm(key, val) {
    this.configBDForm.patchValue({ [key]: val });
    this.closeSelectBox();
  }
  closeSelectBox() {
    setTimeout(() => {
      this.showSuggestionCat = this.showSuggestionSub = this.showSuggestionFlag = false;
    }, 150);
  }
  async getMeta(query?) {
    try {
      const data = (await this.categoryService.getFieldMeta(query)) as any[];
      const formattedData = utils.metaFormat(data);
      if (query.field_type) {
        this.meta = {
          ...this.meta,
          [query.field_type]: formattedData[query.field_type]
        };
      } else {
        this.meta = formattedData;
      }
      console.log(query);
      console.log(this.meta);
    } catch (e) {
      console.error(e);
    }
  }

  setOrdering(event) {
    this.getDBs(undefined, event);
  }

  async setPage($event) {
    this.query.page = $event;
    await this.getDBs($event);
  }

  async save(dbValue, overrides) {
    return await this.flagsDbService.createRevision(dbValue, overrides);
  }

  async edit(dbValue, overrides) {
    return await this.flagsDbService.updateRevision(
      this.editItemId,
      dbValue,
      overrides
    );
  }

  onChanges() {
    this.changed = false;

    this.configBDForm.valueChanges.subscribe(val => {
      const changesList = [];

      Object.keys(val).forEach(key => {
        if (val[key] !== this.itemToEdit[key]) {
          changesList.push(key);
        }
      });
      this.changed = changesList.length > 0;
    });
  }

  async onSubmit(modal) {
    this.submitted = true;
    const dbValue = this.configBDForm.value;
    if (this.configBDForm.invalid) {
      document.querySelector('.modal-content').scrollIntoView();
      return;
    }

    const { oldData, needTocreate } = utils.checkIfNeedToCreateNewMeta(
      dbValue,
      this.meta
    );
    const rulesMeta = this.rs.rules.map(e =>
      utils.formatMetaForRules(e, this.meta)
    );
    const { allWhichExist, uniqNeedToCreate } = utils.prepareMeta(
      needTocreate,
      rulesMeta
    );

    const requestsToCreateMeta = uniqNeedToCreate.map(
      this.categoryService.postFieldMeta
    );
    const createdMeta: any = await Promise.all(requestsToCreateMeta);
    createdMeta.forEach(element => {
      this.onAddData({ item: element, type: element.field_type });
    });
    const metaValues = utils.prepareMetaForDB(
      createdMeta,
      needTocreate,
      oldData
    );
    console.log(createdMeta, allWhichExist, metaValues);
    const RULES = this.rs.prepareRules(createdMeta, allWhichExist);

    console.log(RULES);
    const overrides = this.rs.formatRules(RULES);

    console.log(overrides);
    const DB_VALUES = { ...dbValue, ...metaValues };
    for (const KEY in DB_VALUES) {
      if (DB_VALUES.hasOwnProperty(KEY)) {
        const element = DB_VALUES[KEY];
        if (!element) DB_VALUES[KEY] = null;
        if (KEY == 'can_be_used_in_api') {
          DB_VALUES[KEY] = Boolean(DB_VALUES[KEY]);
        }
      }
    }
    const db = await this[this.mode](DB_VALUES, overrides);
    this.getAllMeta();
    this.closeModal(modal);
    this.sendToModeration = true;
    this.getDBs();
  }

  closeModal(modal) {
    this.submitted = false;
    this.editItemId = null;
    this.itemToEdit = null;
    this.mode = '';
    this.ruleChanged = false;
    modal.close('');
  }

  setMultiBox($event, fieldName) {
    console.log('************');
    console.log(this.metaSelected);
    this.query.search[fieldName] = this.metaSelected[fieldName].map(e => e.id);
    console.log(this.query);
    console.log('************');

    this.getDBs();
  }

  removeMultiBox($event, fieldName) {
    this.query.search[fieldName] = this.metaSelected[fieldName].map(e => e.id);
    this.query.search = { ...this.query.search };
    this.getDBs();
  }

  resetMultiBox(fieldName) {
    this.metaSelected[fieldName] = [];
    this.query.search[fieldName] = [];
    this.query.search = { ...this.query.search };
    this.getDBs();
  }

  searchTriggered($event, fieldName) {
    if (typeof $event === 'string' || $event === null) {
      this.query.search[fieldName] = $event;
    }

    this.query.search = { ...this.query.search };
    this.getDBs();
  }

  addItem(template) {
    this.formReady = true;
    this.mode = 'save';
    this.changed = true;
    this.setConfigForm();
    this.openEditModal(template);
  }

  editDB(template, item) {
    const keys = ['category', 'subcategory', 'flag_category', 'flag_direction'];
    const keysForTextValue = [
      'correlation',
      'fundamental',
      'recommendation',
      'recommendation_if_high',
      'recommendation_if_low'
    ];
    this.mode = 'edit';
    this.formReady = false;
    this.editItemId = item.id;
    this.itemToEdit = { ...item };
    keys.forEach(e => {
      //if item not null
      if (this.itemToEdit[e]) {
        // it its array prepolulate all id
        if (Array.isArray(this.itemToEdit[e])) {
          this.itemToEdit[e] = this.itemToEdit[e].map(e => e.id);
        } else {
          //if its oject prepopulate only one id
          this.itemToEdit[e] = this.itemToEdit[e].id;
        }
      } else {
        this.itemToEdit[e] = null;
      }
    });
    keysForTextValue.forEach(
      e =>
        (this.itemToEdit[e] = this.itemToEdit[e]
          ? this.itemToEdit[e].field_text
          : null)
    );
    console.log(this.itemToEdit);
    this.setConfigForm();
    this.configBDForm.patchValue({ ...this.itemToEdit });
    this.onChanges();

    this.formReady = true;
    this.openEditModal(template);
  }

  setConfigForm() {
    const numberValidator = Validators.pattern('^-?\\d*(\\.\\d+)?$');
    this.configBDForm = this.formBuilder.group({
      category: ['', [Validators.required]],
      correlation: ['', [Validators.required]],
      flag_category: ['', [Validators.required]],
      flag_direction: ['', []],
      fundamental: ['', [Validators.required, numberValidator]],
      max: ['', [Validators.required, numberValidator]],
      min: ['', [Validators.required, numberValidator]],
      muscle_id: ['', [numberValidator]],
      recommendation: [''],
      recommendation_if_high: [''],
      recommendation_if_low: [''],
      subcategory: ['', [Validators.required]],
      scientific_name: [''],
      function: [''],
      areas_affected_muscle_origin_insertion: [''],
      conditions_complaints: [''],
      recommendations_sources: [''],

      can_be_used_in_api: [false]
    });
  }

  openEditModal(template) {
    this.modalService.open(template, { size: 'lg', backdrop: 'static' });
  }

  catchEditingErrors(error) {
    for (const key in error) {
      const i = this.fields.findIndex(f => f.name === key);
      if (i >= 0) {
        this.fields[i].hasErrors = true;
      }
    }
  }

  async removeVersion() {
    try {
      await this.flagsDbService.removeRevision(this.toDelete);
      this.sendToModeration = true;
    } catch (e) {
    } finally {
      this.getDBs();
      this.closeDeleteModal();
      this.getMeta({ field_type: 'correlation', is_used: true });
    }
  }

  async getDBs(page = 1, ordering?) {
    const search = {};
    if (this.query)
      for (const key in this.query.search) {
        if (this.query.search.hasOwnProperty(key)) {
          this.query.search[key]
            ? (search[key] = this.query.search[key])
            : null;
        }
      }

    this.loaded = false;
    const query = ordering
      ? { ...this.query, ...search, page, ordering }
      : { ...this.query, ...search, page };

    delete query.search;
    if (query.overrides == 'All') delete query.overrides;
    const resp = (await this.flagsDbService.get(query)) as any;

    this.results = resp.results;

    this.totalItems = resp.count;
    this.totalPages = resp.total_pages;
    this.query.limit = resp.limit;
    this.loaded = true;
  }

  showDeleteModal(row) {
    this.deleteModal = true;
    this.toDelete = row.id;
  }

  closeDeleteModal() {
    this.deleteModal = false;
    this.deleteFlagsDbModal = false;
    this.toDelete = null;
  }

  redirect() {
    this.tempDB = null;
    this.router.navigateByUrl(this.redirectUrl);
  }

  async importCsv({ target, target: { value, files } }) {
    if (
      value
        .split('.')
        .reverse()[0]
        .toLowerCase() !== 'csv'
    ) {
      return this.popUpService.showPopUp('error', 'File format is incorrect');
    }
    const file = files[0];
    try {
      await this.userService.uploadFlagCsv(file);
      this.popUpService.showPopUp(
        'success',
        StorageService.get('user').is_moderator
          ? 'CSV Row uploaded!'
          : 'CSV Row uploaded and added to revision!'
      );
      this.getDBs();
    } catch (error) {
      this.popUpService.showPopUp('error', 'Upload failed');
    } finally {
      target.value = null;
    }
  }
  onRemoveMeta({ id, type }) {
    this.results = this.results.map(row => {
      const shouldReplace = row[type] && row[type].id == id;
      return {
        ...row,
        [type]: shouldReplace ? null : row[type]
      };
    });
    const index = this.meta[type].findIndex(e => e.id == id);
    const tempMeta = [...this.meta[type]];
    tempMeta.splice(index, 1);
    this.meta[type] = [...tempMeta];
  }
  onAddData({ item, type }) {
    this.meta[type] = this.meta[type]
      ? [...this.meta[type], item].sort((a, b) =>
          utils.alphabeticallySort(a.field_text, b.field_text)
        )
      : [item];
  }
  onEditMeta(item) {
    const type = item.field_type;
    this.results = this.results.map(row => {
      const shouldReplace = row[type] && row[type].id == item.id;
      return {
        ...row,
        [type]: shouldReplace ? { ...item } : row[type]
      };
    });
    const index = this.meta[type].findIndex(e => e.id == item.id);
    const tempMeta = [...this.meta[type]];
    tempMeta[index] = { ...tempMeta[index], ...item };
    this.meta[type] = [...tempMeta];
  }
  appFilterChange($event) {
    this.query.overrides = $event;
    this.getDBs();
  }
}
