import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from '../services/auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from '../services/token.interceptor';
import {UserService} from '../services/user.service';
import {APIInterceptor} from '../services/apiurl.interceptor';
import {HttperrorsInterceptor} from '../services/httperrors.interceptor';
import {TagsService} from '../services/tags.service';
import {TitlesService} from '../services/titles.service';
import {ConfigDbService} from '../services/config-db.service';
import {KeyService} from '../services/key.service';
import {GroupsService} from '../services/groups.service';
import {FlagsDbService} from '../services/flags-db.service';
import { RuleService } from '../services/rules.service';

// MODULE FOR SINGLETONE SERVICES
// ADD PROVIDERS ONLY

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthService,
    TitlesService,
    ConfigDbService,
    TagsService,
    UserService,
    GroupsService,
    FlagsDbService,
    RuleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttperrorsInterceptor,
      multi: true,
    },
    KeyService,
    UserService,
  ],
})
export class FeatureModule {
  static forRoot(): ModuleWithProviders {
    return {
       ngModule: FeatureModule,
       providers: [UserService]
   }
 }
}
