import { Component, OnInit } from '@angular/core';
import { PopUpService } from './services/pop-up.service';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'son-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'soniphi';
  popUps = { type: null };

  constructor(
    public popupService: PopUpService,
    private authService: AuthService,
    private us: UserService
  ) {}

  ngOnInit(): void {
    this.popupService.popUps.subscribe(data => (this.popUps = data));
    const userLoggedIn =
      StorageService.token() &&
      StorageService.get('user') &&
      StorageService.get('userID');
    userLoggedIn && this.refreshUser();
    userLoggedIn && this.us.startWatching();
    
  }
  async refreshUser() {
    try {
      const user: any = await this.authService.getMe();
      StorageService.set('user', user, true);
      StorageService.set('userID', user.id, true);
    } catch (e) {
      console.log(e);
    }
  }
}
