import {Component, Input, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {DndDropEvent} from 'ngx-drag-drop';


@Component({
  selector: 'son-dnd',
  templateUrl: './dnd.component.html',
  styleUrls: ['./dnd.component.scss'],
  providers: [NgbDropdownConfig]
})

export class DndComponent implements OnInit {
  @Input() list: any;
  @Input() callback = (list) => {};

  constructor(config: NgbDropdownConfig) {
    config.autoClose = false;
  }

  ngOnInit() {
  }

  onChange(index) {
    this.list[index].isActive = !(this.list[index].isActive);
    this.callback(this.list);
  }

  onDragged(item: any, list: any[], i: number) {
    this.callback(this.list);
  }

  onDrop(event: DndDropEvent, list: any[]) {
    let newIndex = event.index;
    const oldIndex = event.type;

    if (typeof newIndex === 'undefined') {
      newIndex = list.length;
    }

    const draggedItem = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, draggedItem);
  }

}
