import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'son-covid-symptoms',
  templateUrl: './covid-symptoms.component.html',
  styleUrls: ['./covid-symptoms.component.scss']
})
export class CovidSymptomsComponent implements OnInit {
  @Input()
  symptoms: { symptom_text: string; reply: boolean }[] = [];
  @Input()
  covid_status: { id: number; status: string };
  @Input()
  idUser: number;
  @Input()
  qpcFile: string;
  fileUrl: string;
  downloading: boolean = false;

  constructor(private us: UserService) {}

  ngOnInit() {}
  async downloadFile() {
    if (this.downloading) return;
    this.downloading = true;
    await this.us.downloadQpcData(this.idUser, this.qpcFile);
    this.downloading = false;
  }
}
