import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  get = () => {
    return this.http.get('categories/').toPromise();
  };

  patch = (id, category) => {
    return this.http.patch(`categories/${id}/`, category).toPromise();
  };

  getFlagsMeta = () => {
    return this.http.get(`constants/flags/meta/`).toPromise();
  };
  getFieldMeta = (params = {}) => {
    return this.http
      .get(`constants/flag-fields/`, { params: { ...params } })
      .toPromise();
  };
  postFieldMeta = body => {
    return this.http.post('constants/flag-fields/', body).toPromise();
  };
  deleteMeta = id => {
    return this.http.delete(`constants/flag-fields/${id}/`).toPromise();
  };
  putMeta = (id, data) => {
    return this.http.put(`constants/flag-fields/${id}/`, data).toPromise();
  };
}
